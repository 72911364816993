<template>
  <div>
    <div class="content" @click='showtype=false'>
      <div class="all">
          <div class="tab">
              <el-tabs v-model="activeName" @tab-click="handleClick"  style="margin-left:25px">
                <el-tab-pane label="待审批" name="one"></el-tab-pane>
                <el-tab-pane label="审批记录" name="two"></el-tab-pane>
                </el-tabs>

            <div class="SearchBar">
              <!--搜索框-->
              <el-form ref="search" :inline="true" :model="search" size="small" >
                <el-row>
                  <el-form-item label="工程编号/名称" class="lab">
                    <el-input v-model="search.keyWord" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="现场管理员" class="lab">
                    <el-input v-model="search.siteManager" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="第三方施工队" class="lab">
                    <el-input v-model="search.constructionTeam" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="带气等级" class="lab">
                    <el-select v-model="search.jobLevel" clearable placeholder="请选择" class="SearchOptions">
                      <el-option
                          v-for="item in jobLevelList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="审核状态" class="lab" v-if="activeName=='two'">
                    <el-select v-model="search.checkType" clearable placeholder="请选择" class="SearchOptions">
                      <el-option
                          v-for="item in statusList"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <el-form-item label="" class="lab" v-if="activeName=='two'">
                  <el-select v-model="search.time"  placeholder="请选择" class="SearchOptions2">
                    <el-option
                        v-for="item in dateTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="提交时间" class="lab" v-if="activeName=='one'">

                </el-form-item>
                <el-form-item label="">
                  <el-date-picker
                      class="SearchDate"
                      v-model="searchDatatime"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      clearable
                      value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>



                <span>
            <el-button type="primary" class="SearchBtn" size="small" @click="getList(1)">查询</el-button>
            <el-button type="primary" plain class="SearchBtn2" size="small" @click="reset">重置</el-button>
           </span>
              </el-form>
            </div>
        <div class="table">
          <!--数据表格-->
          <el-table  height="calc(100vh - 328px)" :header-cell-style="{background:'#F9FAFE'}" ref="tableData" :data="tableData1" style="width: 100%;margin: 15px 0;" border   v-if="activeName=='one'"
             
            :stripe="true"
          >
           <el-table-column
                type="index"
                label="序号"
                align="center"
                >
                </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="constructionTeam"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
            >
           <template slot-scope="{ row }">
            <span @click="desc(row)">{{row.constructionTeam}}</span>
            </template>
            </el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
           
            <el-table-column
              prop="moldyBy"
              label="提交人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="moldyTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
              sortable
            >
            
            </el-table-column>
            <el-table-column
              prop="acceptanceCheckWorkUrl"
              label="带气方案"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
            <el-button size="small" plain  @click="showImg(row)" >查看</el-button>
            </template>
            </el-table-column>
            <el-table-column
                prop="jobLevelName"
                label="带气等级"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="200">
              <template slot-scope="{ row }">
                   <el-button
                    type="success"
                    size="small"
                    @click="addShow(row,1)" 
                    >通过</el-button>
                     <el-button
                    type="danger"
                    size="small"
                    @click="addShow(row,2)" 
                    >驳回</el-button>
                      
                    
              </template>
            </el-table-column>
          </el-table>
          <el-table height="calc(100vh - 445px)" :header-cell-style="{background:'#F9FAFE'}" ref="tableData" :data="tableData2" style="width: 100%;margin: 15px 0;" border   v-if="activeName=='two'"
             
            :stripe="true"
          >
            <el-table-column
                type="index"
                label="序号"
                align="center"
                >
                </el-table-column>
            <el-table-column
              prop="projectNumber"
              label="工程编号"
              align="center"
              show-overflow-tooltip
              
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="constructionTeam"
              label="第三方施工队"
              align="center"
              show-overflow-tooltip
            >
           <template slot-scope="{ row }">
            <span @click="desc(row)">{{row.constructionTeam}}</span>
            </template>
            </el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="jobLevel"
              label="带气等级"
              align="center"
              show-overflow-tooltip
            >
           <template slot-scope="{ row }">
            <span >{{row.jobLevel==1?'一级':row.jobLevel==2?'二级':'三级'}}</span>
            </template>
            </el-table-column>
           
            
         
            <el-table-column
              prop="createBy"
              label="提交人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
             <el-table-column
              prop="operationBy"
              label="审批人"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="operationTime"
              label="审批时间"
              align="center"
              show-overflow-tooltip
            >
            
            </el-table-column>
          <el-table-column
              prop="operationType"
              label="审批结果"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="{ row }">
              <el-tag v-if="row.operationType==1" @click="addShow(row,1)" type="primary" style="background-color:#67C23A ;color:#fff" size="small" plain>通过</el-tag>
              <el-tag v-if="row.operationType==2" @click="addShow(row,2)" type="info" style="background-color:#E94242;color:#fff" size="small" plain>驳回</el-tag>
            </template>
            </el-table-column>
            <el-table-column prop="operation" label="操作" align="left" width="200">
              <template slot-scope="{ row }">
                  
                     <el-button
                    type="primary"
                    size="small"
                    @click="sleveldesc(row)" 
                    >查看详情</el-button>
                      
                    
              </template>
            </el-table-column>
          
          </el-table>
          <div class="pageBox">
          <el-pagination
            :current-page="search.current"
            :background="true"
            :page-sizes="[30, 50, 100]"
            :page-size="search.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        </div>
         </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="clearnF('form')"
    >
    <el-form ref="addform" :label-position="activeName=='one'?'top':'left'" :model="form" label-width="120px"  size="mini" >
        <div v-if="activeName=='one'">
          <el-form-item  label="审批意见:" v-if="type==1">
             <el-input type="textarea" v-model="form.proposal"   :autosize="{ minRows: 2, maxRows: 4}" maxlength="100" ></el-input>
          </el-form-item>
          <el-form-item label="驳回建议:" v-if="type==2"  :rules="[{message: '请输入驳回原因', trigger: 'blur' ,required: true, }]">
             <el-input type="textarea" v-model="form.proposal"   :autosize="{ minRows: 2, maxRows: 4}" maxlength="100" ></el-input>
          </el-form-item>
        </div>
        <div v-if="activeName=='two'">
          <el-form-item  label="审批意见:" v-if="type==1">
            {{form.proposal}}
          </el-form-item>
          <el-form-item label="驳回建议:" v-if="type==2" >
            {{form.proposal}}
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="success" @click="saveadd">保存</el-button>
     </div>
    </el-dialog>
    <show-img :imgList="imgList" ref="imgList"></show-img>
    <work-desc :objData="objData"  ref="workDesc"></work-desc>
    <level-desc :objData="objData"  ref="levelDesc"></level-desc>
  </div>
</template>
<script>
import {selectDictListByParentCode } from '@/apis/commonType'
import {gasHomework_ministerApprovalPending,gasHomework_superiorLeadersApproval,gasHomework_leaderRecordsList} from '../../RequestPort/takeGas/takeGas'
import showImg from '../gongfu/showImg.vue'
import workDesc from './workDesc.vue'
import levelDesc from './levelDesc.vue'


export default {
  name: 'reportApply',
  components: {showImg,workDesc,levelDesc},
  mixins: [],
  data() {
    return {
      userList:[],
      search: { current: 1, size: 100,time:1}, //搜索
      searchDatatime:[],
      total: 0,
      tableData1: [], //列表对象
      tableData2: [],
      id:'',
      activeName:'one',
      dialogVisible:false,
      imgList:[],
      rule:{
        
       },
       objData:{},
       form:{},
       dateTypeList:[{value:1,label:'审批时间'},{value:2,label:'提交时间'}],
       jobLevelList:[{value:1,label:'一级'},{value:2,label:'二级'},{value:3,label:'三级'}],
       statusList:[{value:1,label:'通过'},{value:2,label:'驳回'}],
       type:''
    }
  },
  mounted() {
      
      this.getList();
  },
  methods: {
      handleClick(val){
          this.getList(1);
      },
     showImg(row){
          if(row.moldyUrl){
             this.$refs.imgList.setdialogVisible(true);
             this.imgList = row.moldyUrl.split(',');
          }else{
              this.$message.error('暂无竣工签证单照片')
          }
          
      },
     sleveldesc(row){
        this.objData = row;
        this.$refs.levelDesc.setdialogVisible(true)
     },
      desc(row){
      this.id = row.id+""
      this.$refs.workDesc.setdialogVisible(true)
    },
      addShow(row,type){
          if(this.activeName=='two'){
            if(this.form.poperationReject){
                this.form.proposal = row.poperationReject;
            }else{
              this.form.proposal = row.operationProposal;
            }
              
          }
          this.type = type;
          this.form.homeWorkId = row.homeWorkId;
          this.dialogVisible = true;
      },
    
      //重置查询条件
    reset() {
      ;(this.search = {
        current: 1,
        size: 100,
        timeType:1
      }),
       this.searchDatatime=[];
        this.getList(1)
    },
    getList(current) {
      this.search.startTime = ''
        this.search.endTime = ''
      if(this.searchDatatime){
        this.search.startTime = this.searchDatatime[0]
        this.search.endTime = this.searchDatatime[1]
      }
      
      if (current) {
        this.search.current = 1
      }
      //查列表
      if(this.activeName=='one'){
          if (current) {
                this.search.current = 1
            }
            this.search.type = 3
          gasHomework_ministerApprovalPending(this.search).then(res => {
                this.tableData1 = res.data.records
                this.total = res.data.total
            })
      }
      if(this.activeName=='two'){
        if (current) {
                    this.search.current = 1
            }
            this.search.type = 3
            gasHomework_leaderRecordsList(this.search).then(res => {
                this.tableData2 = res.data.records
                this.total = res.data.total
            })
      }
        
    },
   

    handleSizeChange(val) {
        if(this.activeName=='one'){
            this.search1.size = val
        }else{
            this.search.size = val
        }
      
      this.getList(1)
    },
    handleCurrentChange(val) {
        if(this.activeName=='one'){
            this.search1.current = val
        }else{
            this.search.current = val
        }
      this.getList()
    },
  
   
    
    saveadd(){
      this.$refs['addform'].validate(valid => {
            if (valid) {
                if(this.type==1){
                    var data = {checkType:1,homeWorkId:this.form.homeWorkId,type:3,proposal:this.form.proposal}
                    gasHomework_superiorLeadersApproval(data).then(res=>{
                        if(res.code==200){
                            this.$message.success('通过成功！')
                            this.dialogVisible = false;
                            this.getList();
                            this.clearnF();
                        }else{
                             this.$message.error(res.msg)
                        }
                        
                    })
                }
                if(this.type==2){
                    var data = {checkType:2,homeWorkId:this.form.homeWorkId,type:3,reject:this.form.proposal}
                    gasHomework_superiorLeadersApproval(data).then(res=>{
                        if(res.code==200){
                            this.$message.success('驳回成功！')
                            this.dialogVisible = false;
                            this.getList();
                            this.clearnF();
                        }else{
                             this.$message.error(res.msg)
                        }
                        
                    })
                }   
                
            }
            })
    },
    clearnF(){
        this.form={
            proposal:'',
            id:''
        }
    }
   

}}
</script>

<style  lang="less" scoped>

::v-deep .el-upload--picture-card{width:100px;height:100px;line-height:100px}
::v-deep .el-upload-list--picture-card .el-upload-list__item{width:100px;height:100px;line-height:100px}
.tab{background-color: #fff;border-radius: 6px;margin: 10px;}
.searchType{width:210px;box-shadow: 1px 1px 5px #888888;position:absolute;z-index:100;background-color:#fff}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin-right: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.SearchOptions{
  width: 150px;
}
.SearchOptions2{
  width: 100px;
}
.SearchDate{
  width: 300px;
}
.SearchBtn {
  margin-left: 3.7%;
}
.SearchBtn2 {
  margin-left: 20px;
}
.table {
  border-radius: 5px;
   background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
}
.diaBtn {
  margin-right: 2%;
}
.diaInput {
  width: 82%;
}
.lab {
  margin-left: 1%;
}
.dialog_form {
  margin: 30px;
  padding: 10px;
}
.red {
  color: #f74b4b;
}
.radioList{
  display:block;margin:10px 0px
}
</style>